* {
    margin: 0;
    padding: 0;
}
body {
    font-family: Montserrat, sans-serif;
    color: #a1a6b3;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0 !important;
}
dl, ol, ul {
    margin-bottom: 0 !important;
}
b {
    font-weight: 500 !important;
}
button {
    border: none;
    background: none;
    font: 400 13px Montserrat;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
hr {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    border-top-color: transparent !important;
}
.no_margin { margin: 0 !important; }
.mt_5 { margin-top: 5px; }
.mt_10 { margin-top: 10px; }
.mb_5 { margin-bottom: 5px; }
.mt_1 { margin-top: 1px; }
button:focus, select:focus { outline: none !important; }
.vertical_scroll { overflow-y: scroll; }
.vertical_scroll::-webkit-scrollbar-track { border-radius: 0; background-color: #f6f6f6; }
.vertical_scroll::-webkit-scrollbar { width: 5px; height: 5px; background-color: #f6f6f6; }
.vertical_scroll::-webkit-scrollbar-thumb { border-radius: 0; background-color: #55c0e9; }

.map {
    width: 100%;
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}
.controls {
    color: #212529;
}
.control_left_top {position: absolute; left: 10px; top: 10px; z-index: 3;}
.control_left_bottom {position: absolute; left: 10px; bottom: 35px; z-index: 2;}
.control_center_top {position: absolute; left: calc(50% - 150px); top: 10px; z-index: 1;}
.control_right_top {position: absolute; right: 10px; top: 45px; z-index: 2;
    /* DELETE */
    top: 10px;
}
.control_right_bottom { position: absolute; right: 10px; bottom: 30px; z-index: 2;}

.ol-zoom.ol-unselectable.ol-control {
    top: calc(50% - 25px);
    right: 0;
    left: auto;
    padding: 0 10px 0 0;
    border-radius: 0;
    background-color: transparent;
}
.ol-zoom .ol-zoom-in, .ol-zoom .ol-zoom-out {
    border-radius: 0 !important;
    background-color: #55c0e9;
    color: white;
    width: 30px;
    height: 30px;
    font-size: 32px;
    line-height: 0;
    display: block;
    font-weight: 100;
    margin: 0;
    font-family: Montserrat, sans-serif;
}
.ol-zoom .ol-zoom-out {
    margin-top: 1px;
}
.ol-zoom .ol-zoom-in:hover, .ol-zoom .ol-zoom-out:hover {
    background-color: #7b75ec;
}
.ol-control button:hover, .ol-control button:focus {
    background-color: #55c0e9 !important;
}
.ol-full-screen {
    right: 10px !important;
    top: 10px !important;
}
.ol-full-screen.ol-unselectable.ol-control {
    background: transparent;
    padding: 0;
    border-radius: 0;
    /* DELETE */
    display: none;
}
button.ol-full-screen-false {
    background-color: #55c0e9 !important;
    background: transparent;
    color: transparent !important;
    font-family: 'Line Awesome Free';
    font-weight: 900 !important;
    margin: 0 !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 20px !important;
    border-radius: 0 !important;
}
button.ol-full-screen-false:before {
    content: "\f065";
    color: #fff;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 1.5;
}
button.ol-full-screen-true {
    background-color: #55c0e9 !important;
    background: transparent;
    color: transparent !important;
    font-family: 'Line Awesome Free';
    font-weight: 900 !important;
    margin: 0 !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 20px !important;
    border-radius: 0 !important;
}
button.ol-full-screen-true:before {
    content: "\f00d";
    color: #fff;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 1.5;
}
.ol-overviewmap.ol-unselectable.ol-control.ol-collapsed {
    border-radius: 0 !important;
    padding: 0 !important;
    background: transparent !important;
} 
.ol-overviewmap button {
    background-color: #55c0e9 !important;
    background: transparent;
    color: transparent !important;
    font-family: 'Line Awesome Free';
    font-weight: 900 !important;
    margin: 0 !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 20px !important;
    border-radius: 0 !important;
}
.ol-overviewmap.ol-unselectable.ol-control button:before {
    content: "\f00d";
    color: #fff;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 1.5;
}
.ol-overviewmap.ol-unselectable.ol-control.ol-collapsed button:before {
    content: "\f59f";
    color: #fff;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 1.5;
}
.ol-overviewmap button, .ol-overviewmap:not(.ol-collapsed) button {
    left: 0 !important;
    bottom: 0 !important;
}
.ol-overviewmap button {
    margin-bottom: 0 !important;
}
.ol-overviewmap:not(.ol-collapsed) {
    background: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
}
.ol-overviewmap {
    left: 11px !important;
    bottom: 30px !important;
}
.ol-overviewmap .ol-overviewmap-map {
    border: 1px solid #55c0e9 !important;
    margin: 0 0 0 30px !important;
    display: table-cell !important;
}
.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
    display: none !important;
}

.primary_btn {
    background-color: #55c0e9;
    color: white;
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 0;
    display: block;
}
.primary_btn:hover {
    background-color: #7b75ec;
}
.primary_btn:focus {
    background-color: #7b75ec;
}
.title_header {
    background-color: #55c0e9;
    font-size: 15px;
    font-weight: 400;
    padding: 0 10px;
    color: white;
    height: 30px;
    line-height: 1.9;
}
.title_header .las {
    padding-right: 3px;
}
.main_list {
    list-style: none;
}
.main_list.vertical_scroll {
    max-height: 490px;
}
.filter_sublist {
    padding-left: 15px;
}
.list_item_btn {
    font-size: 13px;
    width: calc(100% - 20px);
    padding: 10px 0 5px;
    border-bottom: 1px solid #55c0e9;
    margin: 0 10px;
}
.list_item_btn.disabled {
    opacity: .5;
}
.list_item_info {
    font-size: 13px;
    width: calc(100% - 20px);
    padding: 0;
    border-bottom: 1px solid #55c0e9;
    margin: 0 10px;
}
.list_item_desc {
    margin: 10px 0;
    padding: 10px;
    font-weight: 400;
    background-color: #d4eef9;
}
.list_item_btn_text {
    float: left;
    padding-left: 7px;
    text-align: left;
    width: 92%;
}
.list_item_btn_icon {
    float: right;
    color: #55c0e9;
}
.list_item_desc_list {
    padding-left: 20px;
    list-style-type: none;
}
.list_item_desc_list li::before {
    content: "\2022";
    color: #7b75ec;
    font-weight: bold;
    display: inline-block; 
    width: 15px;
    margin-left: 0;
}

.input_select {
    font-size: 13px;
    width: calc(100% - 20px);
    padding: 3px 5px 5px;
    border: 1px solid #55c0e9;
    margin: 10px 10px 0;
    opacity: 0.7;
}
.input_select:focus {
    opacity: 1;
}
.input_select option {
    background: white;
    color: #212529;
    font-size: 13px;
    padding: 3px 5px 5px;
}
.input_select option[disabled] {
    color: #a1a6b3;
}
.input_select option:hover {
    background-color: #a1a6b3;
}

.footer_info {
    color: #fff;
    position: absolute;
    bottom: 0;
    z-index: 1;
    background: #a1a6b3;
    width: 100%;
    height: 20px;
    text-align: center;
    font-size: 11px;
    line-height: 1.8;
    overflow: hidden;
}

.hidden_filter, .hidden_settings, .hidden_search, .hidden_help, .hidden_info, .hidden_smallMap, .hidden_layers {
    display: none;
}
.hidden_list {
    display: none;
}
.show_list {
    display: block;
}
.show_part {
    display: block;
}
.hidden_part {
    display: none;
}

/* FILTER */
.filter_box {
    background-color: white;
    opacity: .9;
    width: 300px;
    padding-bottom: 15px;
}
.filter_btn_box {
    display: inline-block;
    margin-left: 0;
    position: absolute;
}
.show_filter {
    display: inline-block;
    position: absolute;
    left: 30px;
}
.filter_btns_box {
    width: 100%;
    padding: 10px 15px 0;
    text-align: right;
}
.text_btn {
    background-color: #55c0e9;
    color: white;
    font-size: 14px;
    display: inline-block;
    height: 30px;
    padding: 0 7px;
    line-height: 0;
}
.list_item_with_btns {
    position: relative;
    display: table;
    width: calc(100% - 20px);
    padding: 10px 10px 7px;
    margin: 0 10px;
    border-bottom: 1px solid #55c0e9;
}
.list_item_with_btns_text {
    float: left;
    padding-left: 7px;
    text-align: left;
    display: table-cell;
}
.filter_list {
    background-color: white;
    width: 300px;
    padding-bottom: 15px;
}
.show_filter_list {
    position: absolute;
    top: 45px;
    left: calc(50% - 150px);
    background-color: #fff;
    z-index: 2;
    opacity: 0.9;
    display: block;
}
.filter_list_btns {
    display: table-cell;
    float: right;
}
.list_btn {
    font-size: 18px;
    line-height: 0;
    color: #55c0e9;
}
/* /FILTER */

/* SETTINGS */
.settings_box {
    background-color: white;
    opacity: .9;
    width: 300px;
    padding-bottom: 15px;
}
.settings_btn_box {
    display: inline-block;
    margin-left: 0;
}
.show_settings {
    display: inline-block;
    position: absolute;
    right: 30px;
}
/* /SETTINGS */

/* INFO */
.info_btn_box {
    display: inline-block;
    margin-left: 0;
}
.info_header_btns {
    position: absolute;
    top: 0;
    right: 0;
}
.info_header_btns .primary_btn {
    display: inline-block;
    float: left;
}
.info_header_btns .primary_btn:hover {
    background-color: #55c0e9;
}
.info_header_btns .primary_btn:not(:first-child) {
    border-left: 1px solid #fff;
}
.primary_btn.hidden_btn {
    display: none;
}
.primary_btn.show_hidden_btn {
    display: inline-block;
}
.info_title {
    font-size: 13px;
    text-align: center;
    background: #d1cff8;
    height: 20px;
    line-height: 1.4;
}
#infoBox {
    position: absolute;
    z-index: 9;
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    top: 45px;
    left: calc(50% - 250px);
}
#dragHeader {
    cursor: move;
}
/* /INFO */

/* SMALL MAP */
.smallMap_btn_box {
    display: inline-block;
    margin-left: 0;
}
.show_smallMap {
    display: inline-block;
    position: absolute;
    right: 30px;
    bottom: 0;
}
.smallMap_img_box {
    margin: 10px;
}
.smallMap_img {
    width: 100%;
    max-width: 170px;
    margin: 0 auto;
    display: block;
}
/* /SMALL MAP */

/* SEARCH */
.search_box {
    background-color: white;
    opacity: .8;
    width: 300px;
}
.search_input {
    width: 100%;
    font-size: 14px;
    padding: 0 7px;
    border: 1px solid #55c0e9;
    position: absolute;
    height: 30px;
}
.search_input:focus {
    outline: none;
}
.search_btns {
    font-size: 18px;
    top: 0;
    right: 0;
    position: absolute;
    height: 30px;
}
.search_btns .filter_btn {
    margin-right: -6px;
    height: 30px;
}
.search_btns .filter_btn i {
    border-left: 1px solid #212529;
    padding: 0 7px;
}
.search_btns .search_btn {
    background-color: #55c0e9;
    color: #fff;
    padding: 0 7px;
    height: 30px;
}
.search_btns .search_btn:hover {
    background-color: #7b75ec;
}
/* /SEARCH */

/* INFO BOX MODAL */
/* .infoBox_modal {
    position: absolute;
    top: 45px;
    left: calc(50% - 250px);
    background-color: #fff;
    z-index: 4;
} */
.info_subtitle_header {
    position: relative;
}
.info_subtitle_btn {
    width: 30px;
    height: 20px;
    font-size: 18px;
    line-height: 1.1;
    display: block;
}
.infoBox_content {
    max-width: 500px;
    max-height: 70vh;
    display: block;
    padding: 0 10px 10px;
}
.infoBox_table {
    font-size: 13px;
}
.infoBox_table tr {
    border-bottom: 1px solid #55c0e9;
}
.infoBox_table tr td {
    padding: 5px 10px 3px;
}
.la-angle-down:before {
    content: "\f107" !important;
}
/* /INFO BOX MODAL */

/* LAYERS */
.layers_box {
    background-color: white;
    opacity: .9;
    width: 220px;
    padding-bottom: 15px;
}
.layers_btn_box {
    display: inline-block;
    margin-left: 0;
}
.show_layers {
    display: inline-block;
    position: absolute;
    right: 30px;
    bottom: 0;
}
.list_item_checkbox {
    font-size: 16px;
    width: calc(100% - 20px);
    padding: 5px 0 0;
    border-bottom: 1px solid #55c0e9;
    margin: 0 10px;
}
.list_item_checkbox label {
    margin-bottom: 0;
    font-size: 13px;
}
.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}
.checkbox > .label {
    padding: 0 10px;
    line-height: 1;
}
.checkbox > input {
    height: 15px;
    width: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #55c0e9;
    border-radius: 0;
    outline: none;
    transition-duration: 0.3s;
    background-color: #f6f6f6;
    cursor: pointer;
  }
.checkbox > input:checked {
    border: 1px solid #55c0e9;
    background-color: #f6f6f6;
}
.checkbox > input:checked + span::before {
    content: '\f00c';
    display: block;
    text-align: center;
    color: #55c0e9;
    position: absolute;
    left: 1px;
    top: 1px;
}
.checkbox > input:active {
    border: 2px solid #55c0e9;
}
/* /LAYERS */

@media (max-width: 992px) {
    .filter_box, .settings_box, .help_box, .search_box, .infoBox_modal, .layers_box {
        opacity: 1;
    }
    .infoBox_modal {
        top: 45px;
        left: 10px;
        width: 100%;
        height: calc(100vh - 110px);
        overflow: hidden;
    }
    #infoBox {
        width: calc(100% - 20px);
        left: 10px;
    }
    .infoBox_content {
        max-height: none;
        height: inherit;
        padding: 0 10px 40px;
    }
    .control_center_top {
        left: 45px;
        width: calc(100% - 90px);
    }
    .filter_list {
        left: 45px;
        width: calc(100% - 90px);
    }
}